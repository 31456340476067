<template>
  <div class="style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="7">
          <div class="title-header">
            Compléter vos informations
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <!--INFORMATION PERSONNELLE-->
    <div class="info-personelle-body">
      <div class="block-vues">
        <div class="">
          <v-list class="list-info-personel">
            <v-list-item-group>
              <v-list-item
                button
                v-for="params in parametrage"
                :key="params.name"
                @click.prevent="checkedParams(params.name)"
                class="custom-list-group"
              >
                <v-list-item-icon v-if="selectedNameParams == params.name">
                  <font-awesome-icon class="icon-color-cdg" icon="check" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    ><span> {{ params.name }}</span></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
      <div class="block-vue-details">
        <div class="form-info pt-3" v-if="selectedNameParams == 'Mot de passe'">
          <v-form
            v-if="getUserToUpdate"
            class="form-update-info-person pt-3"
            ref="formPassword"
          >
            <v-text-field
              label="Mot de passe actuel"
              v-model="password"
              outlined
              dense
              type="password"
              :error-messages="getErrorInUpdateUser"
              @change="verificationActuelPassword"
              :validate-on-blur="success"
              :rules="[v => !!v || 'Mot de passe actuel est requis']"
              :persistent-placeholder="true"
              color="#704ad1"
              class="mb-3 mt-3 msg-error"
            ></v-text-field>
            <v-text-field
              v-model="getUserToUpdate.password"
              outlined
              dense
              label="Nouveau mot de passe"
              type="password"
              :persistent-placeholder="true"
              @input="success = false"
              :validate-on-blur="success"
              :rules="[
                v => !!v || 'Le mot de passe est requis',
                v =>
                  (v && v.length >= 8) ||
                  'Le mot de passe doit comporter au moins 8 caractères'
              ]"
              autocomplete="new-password"
              color="#704ad1"
              class=" mb-3 msg-error"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Confirmation de mot de passe"
              type="password"
              v-model="getUserToUpdate.password_confirmation"
              :rules="[
                v => !!v || 'La confirmation du mot de passe est requise',
                v =>
                  (v && v === this.getUserToUpdate.password) ||
                  'Les mots de passe ne correspondent pas'
              ]"
              :persistent-placeholder="true"
              :validate-on-blur="success"
              color="#704ad1"
              class="mb-3 msg-error"
            ></v-text-field>
            <div v-if="error" class="message-error-modal mb-2">
              <ul v-if="Array.isArray(error)">
                <li v-for="(error, index) in error" :key="'error' + index">
                  {{ error }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
            <v-btn
              outlined
              class="btn-valid-info"
              color="#704ad1"
              :disabled="!verifyCurrentPassword"
              @click.prevent="UpdateDataUser('password')"
              :loading="isLoading"
              :class="{ loader: isLoading }"
              >Valider</v-btn
            >
            <div v-if="successMessage">
              <div class="success mb-2">
                Vos données personnelles ont été modifiées avec succès !
              </div>
            </div>
          </v-form>
        </div>
        <div
          class="form-info pt-3"
          v-if="selectedNameParams == 'Information personnelle'"
        >
          <v-form
            v-if="getUserToUpdate"
            class="form-update-info-person mt-2"
            ref="formPhoto"
          >
            <div class="profile-pic">
              <label class="-label" for="file">
                <font-awesome-icon icon="camera" />
                <span>Change Image</span>
              </label>
              <input id="file" type="file" @change="loadFile" />
              <img
                src="../../assets/user-empty.jpg"
                v-if="getUserToUpdate.logo == null"
                id="output"
                width="200"
              />
              <img
                v-if="
                  getUserToUpdate.logo &&
                    typeof getUserToUpdate.logo == 'string'
                "
                :src="getUserToUpdate.logo"
                id="output"
                width="200"
              />
              <img
                v-if="
                  getUserToUpdate.logo &&
                    typeof getUserToUpdate.logo == 'object'
                "
                :src="getUserToUpdate.logo"
                id="output"
                width="200"
              />
            </div>
            <v-text-field
              v-model="getUserToUpdate.name"
              outlined
              dense
              :persistent-placeholder="true"
              label="Nom & Prénom"
              type="text"
              color="#704ad1"
              class="mt-2 msg-error"
            ></v-text-field>
            <v-text-field
              v-model="getUserToUpdate.email"
              outlined
              dense
              label="E-mail"
              type="text"
              :persistent-placeholder="true"
              :rules="rules"
              autocomplete="email"
              color="#704ad1"
              class="msg-error"
            ></v-text-field>
            <div v-if="error" class="message-error-modal mb-2">
              <ul v-if="Array.isArray(error)">
                <li v-for="(error, index) in error" :key="'error' + index">
                  {{ error }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
            <div v-if="successMessage">
              <div class="success mb-2">
                Vos données personnelles ont été modifiées avec succès !
              </div>
            </div>
            <v-btn
              outlined
              class="btn-valid-info"
              color="#704ad1"
              @click.prevent="UpdateDataUser('info-personel')"
              :loading="isLoading"
              :class="{ loader: isLoading }"
              >Valider</v-btn
            >
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data() {
    return {
      verifyCurrentPassword: false,
      success: true,
      successMessage: false,
      password: null,
      error: null,
      isLoading: false,
      image: null,
      rules: [
        v => !!v || 'Email est obligatoire',
        v => /.+@.+\..+/.test(v) || 'Format email invalide'
      ],
      selectedNameParams: 'Information personnelle',
      parametrage: [
        { name: 'Information personnelle' },
        { name: 'Mot de passe' }
      ]
    };
  },
  computed: {
    ...mapGetters(['getUserData', 'getErrorInUpdateUser']),
    getUserToUpdate() {
      return {
        password: null,
        password_confirmation: null,
        logo: this.getUserData.photo,
        ...this.getUserData
      };
    }
  },

  methods: {
    ...mapActions(['editUserInfo', 'verificationUser', 'resetErrorUserUpdate']),
    async verificationActuelPassword() {
      this.resetErrorUserUpdate();
      this.success = false;
      this.error = false;
      const response = await this.verificationUser({
        password: this.password
      });
      this.verifyCurrentPassword = response;
    },
    loadFile(event) {
      this.image = document.getElementById('output');
      this.image.src = URL.createObjectURL(event.target.files[0]);
      this.getUserToUpdate.photo = event.target.files[0];
    },
    resetError() {
      this.success = false;
      this.resetErrorUserUpdate();
    },
    checkedParams(name) {
      this.resetErrorUserUpdate();
      this.successMessage = false;
      this.selectedNameParams = name;
      this.success = true;
      this.password = null;
      this.getUserToUpdate.password = null;
      this.getUserToUpdate.password_confirmation = null;
    },
    async UpdateDataUser(name) {
      this.success = false;
      this.successMessage = false;
      this.error = null;
      if (name == 'password') {
        if (this.verifyCurrentPassword == true) {
          if (this.$refs.formPassword.validate()) {
            this.isLoading = true;
            const response = await this.editUserInfo(this.getUserToUpdate);
            if (response === true) {
              this.success = true;
              this.successMessage = true;
              this.isLoading = false;
              this.password = null;
              this.getUserToUpdate.password = null;
              this.getUserToUpdate.password_confirmation = null;
            } else {
              this.success = false;
              this.successMessage = false;
              this.isLoading = false;
              this.error = this.getErrorInUpdateUser;
            }
          }
        }
      } else {
        if (this.$refs.formPhoto.validate()) {
          this.isLoading = true;
          const response = await this.editUserInfo(this.getUserToUpdate);
          if (response === true) {
            this.isLoading = false;
            this.success = true;
            this.successMessage = true;
          } else {
            this.success = false;
            this.successMessage = false;
            this.isLoading = false;
            this.error = this.getErrorInUpdateUser;
          }
        }
      }
    }
  },
  mounted() {
    this.checkedParams('Information personnelle');
  }
};
</script>

<style lang="scss" scoped>
.info-personelle-body {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: calc(100vh - 172px);
  background-color: #f5f5f5c7;
  padding: 10px;
  // border-radius: 15px;
  .block-vues {
    width: 30%;
    padding: 5px 20px;
    background-color: #fff;
    // border-radius: 15px;
    .list-info-personel {
      margin: 6px 6px !important;
      .custom-list-group {
        margin: 10px 0px;
      }
    }
  }

  .block-vue-details {
    width: 70%;
    padding: 5px 20px;
    background-color: #fff;
    // border-radius: 15px;
    margin-left: 20px;
    overflow-y: auto;
  }
}
.form-info {
  // padding: 14px;
  .form-update-info-person {
    width: 60% !important;
    margin: auto;
  }
}
.btn-valid-info {
  float: right;
}
.error {
  color: red;
  font-size: 14px;
  text-align: center;
  background-color: transparent !important;
  font-family: 'Montserrat', sans-serif;
}
.success {
  color: #4caf50;
  background-color: transparent !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 15px;
  margin-top: 6px;

  // padding-left: 35%;
}
.error-password {
  color: red;
  font-size: 10px;
  margin-top: 4px;
  text-align: center;
  font-weight: 700;
}
.ligne-box-setting-header {
  margin-bottom: 17px;
  width: 100% !important;
  margin-top: 9px;
  border-bottom: 1px solid #cccccc73;
}
@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

$circleSize: 165px;
$radius: 100px;
$shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
$fontColor: rgb(250, 250, 250);

.profile-pic {
  color: transparent;
  transition: all 0.3s ease;
  @include object-center;
  position: relative;
  transition: all 0.3s ease;

  input {
    display: none;
  }

  img {
    position: absolute;
    object-fit: cover;
    width: $circleSize;
    height: $circleSize;
    box-shadow: $shadow;
    border-radius: $radius;
    z-index: 0;
  }

  .-label {
    cursor: pointer;
    height: $circleSize;
    width: $circleSize;
  }

  &:hover {
    .-label {
      @include object-center;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 10000;
      color: $fontColor;
      transition: background-color 0.2s ease-in-out;
      border-radius: $radius;
      margin-bottom: 0;
    }
  }

  span {
    display: inline-flex;
    padding: 0.2em;
    height: 2em;
  }
}

/////////////////////////
// Body styling 🐾
/////////---------->

body {
  height: 100vh;
  background-color: rgb(25, 24, 21);
  @include object-center;

  a:hover {
    text-decoration: none;
  }
}
</style>
